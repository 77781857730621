// UserProgressScreen.jsx

import React, { useState } from "react";
import UserRating from "./UserRating";
import UserProgressDetails from "./UserProgressDetails";
import RecentScoreIncrements from "./RecentScoreIncrements";
import { useUpdates } from "../../contexts/UpdatesContext";
import SectionToggle from "../../components/SectionToggle";

const UserProgressScreen = ({ onClose, userName }) => {
  const [activeSection, setActiveSection] = useState("recentScores");

  // Use the useUpdates hook to get the latest values
  const { updates } = useUpdates();
  const userLevel = updates.newLevel;
  const userScore = updates.newScore;
  const levelCompletionPercent = updates.newLevelCompletionPercent;

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  // Define toggle options
  const toggleOptions = [
    { label: "Recent Scores", value: "recentScores" },
    { label: "Rating", value: "userRating" },
  ];

  return (
    <div>
      <UserProgressDetails
        userScore={userScore}
        userLevel={userLevel}
        levelCompletionPercent={levelCompletionPercent}
      />

      <SectionToggle
        options={toggleOptions}
        selectedOption={activeSection}
        onToggle={handleSectionChange}
      />

      {/* Conditional Rendering for sections */}
      {activeSection === "recentScores" && (
        <RecentScoreIncrements userName={userName} onClose={onClose} />
      )}
      {activeSection === "userRating" && (
        <UserRating userName={userName} onClose={onClose} />
      )}
    </div>
  );
};

export default UserProgressScreen;
