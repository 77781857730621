// ConfirmationPrompt.jsx

import React from "react";
import PropTypes from "prop-types";

const ConfirmationPrompt = ({
  title,
  message,
  leftButton,
  rightButton,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className="confirmation-prompt">
      <div className="prompt-body">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="response-buttons">
          <button onClick={onConfirm}>{leftButton}</button>
          <button onClick={onCancel}>{rightButton}</button>
        </div>
      </div>
    </div>
  );
};

ConfirmationPrompt.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationPrompt;
