import React, { useEffect, useState, useContext } from "react";
import { AxiosContext } from "../../contexts/AxiosContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import UserRatingItem from "./UserRatingItem";
import Loader from "../Loader";

const UserRating = ({ userName, onClose, onFollowChange }) => {
  const axiosInstance = useContext(AxiosContext);
  const [userRatingData, setUserRatingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserRatings = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axiosInstance.get("user/rating/");
        setUserRatingData(response.data);
      } catch (err) {
        setError("Failed to fetch user ratings. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserRatings();
  }, []);

  // Handle follow/unfollow state updates
  const handleFollowChange = (newFollowState, userId) => {
    setUserRatingData((prevData) => {
      const updateUserFollowStatus = (user) => {
        if (user.profile.id === userId) {
          return {
            ...user,
            profile: {
              ...user.profile,
              is_followed: newFollowState,
            },
          };
        }
        return user;
      };

      return {
        ...prevData,
        top_users: prevData.top_users.map(updateUserFollowStatus),
        above_user: prevData.above_user
          ? updateUserFollowStatus(prevData.above_user)
          : null,
        current_user: prevData.current_user
          ? updateUserFollowStatus(prevData.current_user)
          : null,
        below_user: prevData.below_user
          ? updateUserFollowStatus(prevData.below_user)
          : null,
      };
    });
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const hasOtherUsers =
    userRatingData?.above_user ||
    userRatingData?.current_user ||
    userRatingData?.below_user;

  return (
    <div className="user-rating-list">
      {/* Top Users Section */}
      <div className="top-users">
        {userRatingData?.top_users.map((userData) => (
          <UserRatingItem
            key={userData.profile.id}
            user={userData.profile}
            rank={userData.rank}
            isCurrentUser={userData.profile.username === userName}
            onClose={onClose}
            onFollowChange={handleFollowChange}
          />
        ))}
      </div>

      {/* Conditionally display the ellipsis separator if there are other users */}
      {hasOtherUsers && (
        <div className="separator">
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </div>
      )}

      {/* Below, Above, and Current User Section */}
      {hasOtherUsers && (
        <div className="other-users">
          {userRatingData?.above_user && (
            <UserRatingItem
              user={userRatingData.above_user.profile}
              rank={userRatingData.above_user.rank}
              isCurrentUser={
                userRatingData.above_user.profile.username === userName
              }
              onClose={onClose}
              onFollowChange={handleFollowChange}
            />
          )}
          {userRatingData?.current_user && (
            <UserRatingItem
              user={userRatingData.current_user.profile}
              rank={userRatingData.current_user.rank}
              isCurrentUser={true}
              onClose={onClose}
              onFollowChange={handleFollowChange}
            />
          )}
          {userRatingData?.below_user && (
            <UserRatingItem
              user={userRatingData.below_user.profile}
              rank={userRatingData.below_user.rank}
              isCurrentUser={
                userRatingData.below_user.profile.username === userName
              }
              onClose={onClose}
              onFollowChange={handleFollowChange}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UserRating;