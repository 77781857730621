// ReactionsFeed.js

import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useDoodleContext } from "../components/DoodleContext";
import Doodle from "../components/Doodle";
import usePagination from "../hooks/usePagination";
import Loader from "../components/Loader";
import { useInView } from "react-intersection-observer";
import { AxiosContext } from "../contexts/AxiosContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown91,
  faArrowDown19,
} from "@fortawesome/free-solid-svg-icons";

const ReactionsFeed = ({
  doodles,
  loggedInUserName,
  pathUsername,
  handleOpenDoodleEditor,
  isViewingOwnWall,
  handleProtectedButtonClick,
}) => {
  const axiosInstance = useContext(AxiosContext);
  const {
    isReactionsOpen,
    doodleReactedTo,
    setDoodleReactedTo,
    reactions,
    setReactions,
  } = useDoodleContext();

  const [mainDoodleLoading, setMainDoodleLoading] = useState(true);
  const [mainDoodleError, setMainDoodleError] = useState(null);

  // Sort order state, saved in local storage if present
  const [sortOrder, setSortOrder] = useState(() => {
    return localStorage.getItem("reactionsSortOrder") || "desc";
  });

  // Endpoint URL with dynamic ordering parameter based on sortOrder
  const orderingParam = sortOrder === "desc" ? "-created" : "created";
  const initialUrl = `/reactions/${
    doodleReactedTo ? doodleReactedTo.id : ""
  }?ordering=${orderingParam}`;

  const { items, setItems, loading, error, loadMore, hasMore, setUrl } =
    usePagination(initialUrl, 10);

  const [isDebouncing, setIsDebouncing] = useState(false);

  const debouncedLoadMore = useCallback(() => {
    if (!isDebouncing && hasMore && !loading) {
      loadMore();
      setIsDebouncing(true);
      setTimeout(() => {
        setIsDebouncing(false);
      }, 100);
    }
  }, [isDebouncing, hasMore, loading, loadMore]);

  const fetchedDoodleRef = useRef(false);

  // Fetch the main doodle
  useEffect(() => {
    const fetchMainDoodle = async () => {
      if (doodleReactedTo && doodleReactedTo.id && !fetchedDoodleRef.current) {
        try {
          setMainDoodleLoading(true);
          const response = await axiosInstance.get(
            `/single-post/${doodleReactedTo.belongs_to_user.username}/${doodleReactedTo.id}/`
          );
          setDoodleReactedTo(response.data);
          setMainDoodleLoading(false);
          fetchedDoodleRef.current = true;
        } catch (error) {
          console.error("Error fetching main post:", error);
          setMainDoodleError("Failed to load the main post.");
          setMainDoodleLoading(false);
          fetchedDoodleRef.current = true;
        }
      }
    };

    fetchMainDoodle();
  }, [doodleReactedTo?.id]);

  // Update URL when sortOrder changes
  useEffect(() => {
    if (
      isReactionsOpen &&
      doodleReactedTo &&
      !mainDoodleLoading &&
      !mainDoodleError
    ) {
      setUrl(`/reactions/${doodleReactedTo.id}?ordering=${orderingParam}`);
    }
  }, [
    isReactionsOpen,
    doodleReactedTo?.id,
    mainDoodleLoading,
    mainDoodleError,
    orderingParam,
    setUrl,
  ]);

  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "200px",
  });

  useEffect(() => {
    if (
      inView &&
      hasMore &&
      !loading &&
      !mainDoodleLoading &&
      !mainDoodleError
    ) {
      debouncedLoadMore();
    }
  }, [
    inView,
    hasMore,
    loading,
    mainDoodleLoading,
    mainDoodleError,
    debouncedLoadMore,
  ]);

  useEffect(() => {
    setReactions(items);
  }, [items, setReactions]);

  // Toggle sort order
  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
    localStorage.setItem("reactionsSortOrder", newSortOrder);
  };

  return (
    <>
      {mainDoodleLoading && <Loader />}
      {!mainDoodleLoading && mainDoodleError && (
        <p className="error-message">{mainDoodleError}</p>
      )}
      {!mainDoodleLoading && !mainDoodleError && doodleReactedTo && (
        <div className="feed">
          <div className="post">
            <Doodle
              doodle={doodleReactedTo}
              typeOfDoodle={"reactions"}
              handleOpenDoodleEditor={handleOpenDoodleEditor}
              handleProtectedButtonClick={handleProtectedButtonClick}
            />
          </div>
        </div>
      )}
      {!mainDoodleLoading && !mainDoodleError && (
        <div className="feed">
          <div className="reactions-header">
            <h2>Reactions:</h2>
            <button
              className="sort-order-button"
              onClick={toggleSortOrder}
              title={
                sortOrder === "desc"
                  ? "Newest at the top"
                  : "Newest at the bottom"
              }
            >
              <FontAwesomeIcon
                icon={sortOrder === "desc" ? faArrowDown91 : faArrowDown19}
                className="sort-order-icon"
              />
            </button>
            {loading && <Loader small />}{" "}
            {/* Small loader beside sort button */}
          </div>

          {reactions.length > 0
            ? reactions.map((doodle) => (
                <div className="post" key={doodle.id}>
                  <Doodle
                    key={doodle.id}
                    doodle={doodle}
                    doodles={doodles}
                    typeOfDoodle={"reactions"}
                    pathUsername={pathUsername}
                    loggedInUserName={loggedInUserName}
                    handleOpenDoodleEditor={handleOpenDoodleEditor}
                    isViewingOwnWall={isViewingOwnWall}
                    reactionsButtonDisabled={false}
                    handleProtectedButtonClick={handleProtectedButtonClick}
                  />
                </div>
              ))
            : !loading && <p className="no-reactions">No reactions</p>}

          {loading && <Loader />}
          {error && <p>{error}</p>}
        </div>
      )}
      <div ref={ref}></div>
    </>
  );
};

export default ReactionsFeed;
