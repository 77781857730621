import React, { useEffect, useState, useContext } from "react";
import { AxiosContext } from "../../contexts/AxiosContext";
import UserSuggestionItem from "./UserSuggestionItem";

const UserSuggestions = () => {
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [visibleUsers, setVisibleUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const axiosInstance = useContext(AxiosContext);

  useEffect(() => {
    const fetchUserSuggestions = async () => {
      try {
        const response = await axiosInstance.get("/user-suggestions/");
        setSuggestedUsers(response.data);
        setVisibleUsers(response.data.slice(0, 3));
      } catch (error) {
        console.error("Error fetching user suggestions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserSuggestions();
  }, [axiosInstance]);

  const handleFollowChange = (userId) => {
    setSuggestedUsers((prevSuggested) => {
      // Update suggestedUsers to mark the user as followed
      const updatedSuggested = prevSuggested.map((user) =>
        user.id === userId ? { ...user, is_followed: true } : user
      );

      // Remove the user from visibleUsers and add a new user if available
      setVisibleUsers((prevVisible) => {
        // Remove the followed user from visibleUsers
        const updatedVisible = prevVisible.filter((user) => user.id !== userId);

        // Find the next user who hasn't been followed and isn't already visible
        const nextUser = updatedSuggested.find(
          (user) =>
            !updatedVisible.some((vUser) => vUser.id === user.id) &&
            !user.is_followed
        );

        // Add the next user to visibleUsers if found
        if (nextUser) {
          return [...updatedVisible, nextUser];
        } else {
          return updatedVisible;
        }
      });

      return updatedSuggested;
    });
  };

  if (loading) {
    return null;
  }

  // If there are no suggested users, don't render anything
  if (!loading && visibleUsers.length === 0) {
    return null;
  }

  return (
    <div className="user-suggestions-container">
      <div className="user-suggestions-heading">Who to follow</div>
      <div className="user-suggestions-list">
        <div className="suggested-users">
          {visibleUsers.map((user) => (
            <UserSuggestionItem
              key={user.id}
              user={user}
              onFollowChange={() => handleFollowChange(user.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserSuggestions;
