// axiosInstance.js

import axios from "axios";
import { BASE_URL } from "./config";
import emitter from "./eventEmitter";

const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: BASE_URL,
  });

  // Function to get the CSRF token from the cookie
  function getCSRFToken() {
    const name = "csrftoken";
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(name + "="))
      ?.split("=")[1];
    return cookieValue;
  }

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    const csrfToken = getCSRFToken();

    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    if (csrfToken) {
      config.headers["X-CSRFToken"] = csrfToken;
    }
    return config;
  });

  // Response interceptor
  instance.interceptors.response.use(
    (response) => {
      const data = response.data;

      if (data.show_mood_prompt !== undefined) {
        emitter.emit("showMoodPrompt", data.show_mood_prompt);
      }

      if (data.instant_message !== undefined) {
        emitter.emit("instantMessage", data.instant_message);
      }

      if (data.new_score !== undefined) {
        emitter.emit("updateNewScore", data.new_score);
      }
      if (data.new_level !== undefined) {
        emitter.emit("updateNewLevel", data.new_level);
      }
      if (data.new_level_completion_percent !== undefined) {
        emitter.emit(
          "updateLevelCompletionPercent",
          data.new_level_completion_percent
        );
      }

      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        emitter.emit("unauthenticated");
        localStorage.removeItem("token");
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance;
