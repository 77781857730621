import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const HelpTooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top", // Adjust placement as needed
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  });

  // Handle outside click to close the tooltip
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isVisible &&
        popperElement &&
        !popperElement.contains(event.target) &&
        !referenceElement.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, popperElement, referenceElement]);

  return (
    <div className="help-tooltip-container">
      {/* Wrap the child element with the click handler */}
      <div
        ref={setReferenceElement}
        onClick={() => setIsVisible((prev) => !prev)}
        className="help-trigger"
      >
        {children}
      </div>

      {/* Tooltip content */}
      {isVisible && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="help-tooltip"
        >
          <div className="help-tooltip-content">
            {content}
            <button className="close-btn" onClick={() => setIsVisible(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

HelpTooltip.propTypes = {
  content: PropTypes.string.isRequired, // Tooltip content
  children: PropTypes.node.isRequired, // Trigger element (icon, button, etc.)
};

export default HelpTooltip;
