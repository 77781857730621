// src/contexts/GlobalStateContext.js

import React, { createContext, useState, useContext, useEffect } from "react";
import emitter from "../eventEmitter";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [showMoodPrompt, setShowMoodPrompt] = useState(false);
  const [instantMessage, setInstantMessage] = useState(null);

  useEffect(() => {
    const handleShowMoodPrompt = (value) => {
      setShowMoodPrompt(value);
    };

    const handleInstantMessage = (instant_message) => {
      setInstantMessage(instant_message);
    };

    const handleFrontendVersionObsolete = () => {
      setInstantMessage(
        "A new version of the app is available. Please reload the page."
      );
    };

    emitter.on("showMoodPrompt", handleShowMoodPrompt);
    emitter.on("instantMessage", handleInstantMessage);
    emitter.on("frontendVersionObsolete", handleFrontendVersionObsolete);

    return () => {
      emitter.off("showMoodPrompt", handleShowMoodPrompt);
      emitter.off("instantMessage", handleInstantMessage);
      emitter.off("frontendVersionObsolete", handleFrontendVersionObsolete);
    };
  }, []);

  return (
    <GlobalStateContext.Provider
      value={{
        showMoodPrompt,
        setShowMoodPrompt,
        instantMessage,
        setInstantMessage,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);
