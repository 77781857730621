// ParentDoodlePreview.js

import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTurnDown,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import ImageWithLoader from "../components/ImageWithLoader";

const ParentDoodlePreview = ({
  isReactionTo,
  parentReaction,
  openReactionsModal,
}) => {
  if (!isReactionTo && !parentReaction) {
    return null; // No parent doodles to display
  }

  const caseOne = !parentReaction;
  const caseTwo = !!parentReaction;

  const handleThumbnailClick = (doodle) => {
    if (openReactionsModal) {
      openReactionsModal(doodle);
    }
  };

  return (
    <div className="parent-doodle-preview-container">
      <div className="parent-doodle-preview">
        {caseOne && isReactionTo && (
          <div className="case-one">
            <div
              className="thumbnail-container"
              onClick={() => handleThumbnailClick(isReactionTo)}
            >
              <Link
                to={`/${isReactionTo.belongs_to_user.username}`}
                className="username"
                onClick={(e) => e.stopPropagation()} // Prevent triggering the thumbnail click
              >
                {isReactionTo.belongs_to_user.username}
              </Link>
              <div className="image-wrapper">
                <ImageWithLoader
                  src={isReactionTo.thumbnail}
                  alt={`${isReactionTo.belongs_to_user.username}'s post`}
                  containerClassName="image-loader-container"
                  imageClassName="thumbnail"
                />
              </div>
              <FontAwesomeIcon
                icon={faArrowTurnDown}
                className="icon-turn-down"
              />
            </div>
          </div>
        )}

        {caseTwo && (
          <div className="case-two">
            <div
              className="thumbnail-container"
              onClick={() => handleThumbnailClick(isReactionTo)}
            >
              <Link
                to={`/${isReactionTo.belongs_to_user.username}`}
                className="username"
                onClick={(e) => e.stopPropagation()}
              >
                {isReactionTo.belongs_to_user.username}
              </Link>
              <div className="image-wrapper">
                <ImageWithLoader
                  src={isReactionTo.thumbnail}
                  alt={`${isReactionTo.belongs_to_user.username}'s post`}
                  containerClassName="image-loader-container"
                  imageClassName="thumbnail"
                />
              </div>
            </div>
            <FontAwesomeIcon icon={faArrowRightLong} className="icon-right" />
            <div
              className="thumbnail-container"
              onClick={() => handleThumbnailClick(parentReaction)}
            >
              <Link
                to={`/${parentReaction.belongs_to_user.username}`}
                className="username"
                onClick={(e) => e.stopPropagation()}
              >
                {parentReaction.belongs_to_user.username}
              </Link>
              <div className="image-wrapper">
                <ImageWithLoader
                  src={isReactionTo.thumbnail}
                  alt={`${isReactionTo.belongs_to_user.username}'s post`}
                  containerClassName="image-loader-container"
                  imageClassName="thumbnail"
                />
              </div>
              <FontAwesomeIcon
                icon={faArrowTurnDown}
                className="icon-turn-down"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParentDoodlePreview;
