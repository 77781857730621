// src/components/UserSettings.js

import React, { useState, useEffect } from "react";
import createAxiosInstance from "../axiosInstance";
import { useUserContext } from "../components/UserContext"; // Import the UserContext hook
import Loader from "../components/Loader"; // Import the Loader component

const axiosInstance = createAxiosInstance();

/**
 * Component for managing user settings.
 *
 * @returns {JSX.Element}
 */
const UserSettings = () => {
  const [username, setUsername] = useState("");
  const [publicProfile, setPublicProfile] = useState(true); // State for public_profile
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true); // State for initial loading
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  const { userName, setUserName } = useUserContext(); // Destructure setUserName from UserContext

  useEffect(() => {
    fetchUserSettings();
  }, []);

  /**
   * Fetches user settings from the backend.
   */
  const fetchUserSettings = async () => {
    setInitialLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get("/user/settings/");
      setUsername(response.data.username);
      setPublicProfile(response.data.public_profile);
      setMessage("");
    } catch (error) {
      handleError("Failed to load user settings, please try again later.");
    } finally {
      setInitialLoading(false);
    }
  };

  /**
   * Handles error messages.
   *
   * @param {string} customMessage - Custom error message.
   */
  const handleError = (customMessage) => {
    setError(customMessage || "Something went wrong, please try again later.");
  };

  /**
   * Handles changes to the username input.
   *
   * @param {Object} e - Event object.
   */
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  /**
   * Handles changes to the public profile checkbox.
   *
   * @param {Object} e - Event object.
   */
  const handlePublicProfileChange = (e) => {
    setPublicProfile(e.target.checked);
  };

  /**
   * Handles form submission to update user settings.
   *
   * @param {Object} e - Event object.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    setError(null);

    try {
      const payload = {
        username,
        public_profile: publicProfile,
      };

      const response = await axiosInstance.post("/user/settings/", payload);
      setMessage(response.data.message || "Settings updated successfully!");

      // Update the username in UserContext only if it was changed
      if (username !== userName) {
        setUserName(username);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorData = error.response.data;
        // Check for specific errors in the response data
        if (errorData.username) {
          handleError("This username is already taken.");
        } else if (errorData.non_field_errors) {
          handleError(errorData.non_field_errors[0]); // Display first non-field error
        } else {
          handleError("Invalid input.");
        }
      } else {
        handleError();
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Renders the main content of the component.
   */
  const renderContent = () => {
    return (
      <div className="user-settings">
        <h2 className="user-settings__title">User Settings</h2>
        {error && (
          <p className="user-settings__error" role="alert">
            {error}
          </p>
        )}
        {message && (
          <p className="user-settings__message" role="status">
            {message}
          </p>
        )}
        <form onSubmit={handleSubmit} className="user-settings__form">
          <div className="user-settings__input-group">
            <label htmlFor="username" className="user-settings__label">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              className="user-settings__input"
              placeholder="Enter new username"
              required
              disabled={loading}
            />
          </div>
          <div className="user-settings__input-group checkbox-group">
            <input
              type="checkbox"
              id="public_profile"
              checked={publicProfile}
              onChange={handlePublicProfileChange}
              className="user-settings__checkbox"
              disabled={loading}
            />
            <label
              htmlFor="public_profile"
              className="user-settings__checkbox-label"
            >
              Allow unauthenticated users to view your wall and posts
            </label>
          </div>
          <button
            type="submit"
            className="user-settings__button"
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Changes"}
          </button>
        </form>
      </div>
    );
  };

  return (
    <div className="main-content">
      {initialLoading ? <Loader size="large" /> : renderContent()}
    </div>
  );
};

export default UserSettings;
