// src/hooks/useUpdatesFetcher.js

import { useEffect, useContext } from "react";
import { AxiosContext } from "../contexts/AxiosContext";
import { useUpdates } from "../contexts/UpdatesContext";
import emitter from '../eventEmitter';
import { FRONTEND_VERSION } from '../version';

const useUpdatesFetcher = (isAuthenticated) => {
  const axiosInstance = useContext(AxiosContext);
  const { setUpdates } = useUpdates();

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchUpdates = async () => {
      try {
        const response = await axiosInstance.get("/updates/");
        const data = response.data;

        setUpdates({
          unreadMessages: response.data.unread_messages,
          unreadNotifications: response.data.unread_notifications,
          openChallenges: response.data.open_challenges_count,
          newScore: response.data.new_score,
          newLevel: response.data.new_level,
          newLevelCompletionPercent: response.data.new_level_completion_percent,
          instantMessage: response.data.message,
          showMoodPrompt: response.data.show_mood_prompt,
        });

        // Compare frontend versions
        const requiredVersion = data.frontend_version;
        if (requiredVersion && requiredVersion !== FRONTEND_VERSION) {
          emitter.emit("frontendVersionObsolete");
        }
      } catch (error) {
        console.error("Failed to fetch updates", error);
      }
    };

    fetchUpdates();
    const interval = setInterval(fetchUpdates, 60000); // Fetch every minute

    return () => clearInterval(interval);
  }, [isAuthenticated, axiosInstance, setUpdates]);
};

export default useUpdatesFetcher;