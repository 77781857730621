// CustomContextMenu.js
import React, { useState, useRef, useEffect, useCallback } from "react";
import { usePopper } from "react-popper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

const CustomContextMenu = ({ children, triggerElement }) => {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  const menuRef = useRef();

  const setRefs = useCallback(
    (node) => {
      menuRef.current = node;
      setPopperElement(node);
    },
    [setPopperElement]
  );

  const handleClick = (event) => {
    event.stopPropagation(); // Prevent click event from propagating to parent elements
    setVisible(!visible);
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (visible) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [visible]);

  // Filter out falsy children
  const validChildren = React.Children.toArray(children).filter(Boolean);

  return (
    <>
      {/* Conditionally render either the provided trigger element or the default ellipsis button */}
      {triggerElement ? (
        React.cloneElement(triggerElement, {
          ref: setReferenceElement,
          onClick: handleClick,
        })
      ) : (
        <div
          ref={setReferenceElement}
          onClick={handleClick}
          className="options-button"
        >
          <FontAwesomeIcon icon={faEllipsis} />
        </div>
      )}

      {visible && (
        <div
          ref={setRefs}
          style={styles.popper}
          {...attributes.popper}
          className="context-menu"
        >
          {React.Children.map(validChildren, (child) => (
            <div
              onClick={() => {
                setVisible(false);
              }}
            >
              {child}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CustomContextMenu;
