// UserAvatar.js

import React from "react";
import PropTypes from "prop-types";
import ImageWithLoader from "../components/ImageWithLoader";

const UserAvatar = ({ avatarUrl, username, userLevel }) => {
  return (
    <div className="user-avatar-container">
      {avatarUrl ? (
        <div className="avatar-image-wrapper">
          <ImageWithLoader
            src={avatarUrl}
            alt={`${username}'s avatar`}
            containerClassName="image-loader-container"
          />
        </div>
      ) : (
        <div className="avatar-initial">{username.charAt(0).toUpperCase()}</div>
      )}
      {userLevel ? <div className="user-level">{userLevel}</div> : null}
    </div>
  );
};

UserAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  username: PropTypes.string.isRequired,
};

export default UserAvatar;
