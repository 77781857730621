// SectionToggle.jsx

import React from "react";
import PropTypes from "prop-types";

const SectionToggle = ({ options, selectedOption, onToggle }) => {
  return (
    <div className="section-toggle">
      {options.map((option) => (
        <button
          key={option.value}
          className={`toggle-button ${
            selectedOption === option.value ? "active" : ""
          }`}
          onClick={() => onToggle(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

SectionToggle.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOption: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default SectionToggle;