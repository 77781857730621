// src/components/SignUpPrompt.js

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SignUpPrompt = ({ onClose }) => {
  return (
    <div className="signup-prompt">
      <h2 className="signup-prompt__title">Join!</h2>
      <p className="signup-prompt__message">
        Sign up or log in to continue.
      </p>
      <div className="signup-prompt__buttons">
        <Link to="/signup" className="signup-prompt__button" onClick={onClose}>
          Sign Up
        </Link>
        <Link to="/signin" className="signup-prompt__button signup-prompt__button--secondary" onClick={onClose}>
          Sign In
        </Link>
      </div>
    </div>
  );
};

SignUpPrompt.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SignUpPrompt;