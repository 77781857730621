import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import { DoodleProvider } from "./components/DoodleContext";
import { UserProvider } from "./components/UserContext";
import { NotificationsProvider } from "./contexts/NotificationsContext";
import { UpdatesProvider } from "./contexts/UpdatesContext";
import { GlobalStateProvider } from "./contexts/GlobalStateContext";
import { AxiosContext } from "./contexts/AxiosContext";
import createAxiosInstance from "./axiosInstance";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Create Axios instance for API calls inside App.js and in nested components using Context API
const axiosInstance = createAxiosInstance();

root.render(
  <GlobalStateProvider>
    <AxiosContext.Provider value={axiosInstance}>
      <UserProvider>
        <DoodleProvider>
          <UpdatesProvider>
            <NotificationsProvider>
              {/* <React.StrictMode> */}
              <App />
              {/* </React.StrictMode> */}
            </NotificationsProvider>
          </UpdatesProvider>
        </DoodleProvider>
      </UserProvider>
    </AxiosContext.Provider>
  </GlobalStateProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
