// ToolSelector.js
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const ToolSelector = ({
  selectedTool,
  onSelectTool,
  tools,
  toolDurabilities,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  // Determine the current tool key based on the selected tool
  const currentToolKey = tools[selectedTool]
    ? selectedTool
    : Object.keys(tools)[0] || "";

  // Close the dropdown when clicking or tapping outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("touchstart", handleClickOutside);
    } else {
      window.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("touchstart", handleClickOutside);
    }

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("touchstart", handleClickOutside);
    };
  }, [isOpen]);

  // Render tool options as buttons
  const renderToolOptions = () => (
    <div className="tool-options">
      {Object.keys(tools).map((key) => (
        <button
          key={key}
          className={`tool-option-button ${
            key === currentToolKey ? "selected" : ""
          }`}
          onClick={() => {
            onSelectTool(key);
            setIsOpen(false);
          }}
          disabled={toolDurabilities[key] === 0}
          title={`${tools[key].label} ${
            toolDurabilities[key] === 0 ? "(Broken)" : ""
          }`}
          tabIndex="0" // Make focusable
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSelectTool(key);
              setIsOpen(false);
            }
          }}
        >
          <span className="tool-label">
            {tools[key].label} (
            {toolDurabilities[key] === Infinity ? "∞" : toolDurabilities[key]})
          </span>
        </button>
      ))}
    </div>
  );

  return (
    <div className={`tool-selector ${isOpen ? "open" : ""}`} ref={dropdownRef}>
      {Object.keys(tools).length > 0 ? (
        <div className="tool-selector-dropdown">
          <button
            id="tool-selector-toggle"
            className="current-tool-button"
            onClick={() => setIsOpen((prev) => !prev)}
            aria-haspopup="listbox"
            aria-expanded={isOpen}
            ref={toggleButtonRef}
          >
            {/* Mobile Display: First character bolded */}
            <span className="tool-preview">
              <strong>{tools[currentToolKey].label.charAt(0)}</strong>
              <span className="tool-durability">
                (
                {toolDurabilities[currentToolKey] === Infinity
                  ? "∞"
                  : toolDurabilities[currentToolKey]}
                )
              </span>
            </span>

            {/* Desktop Display: Full tool name and durability */}
            <span className="tool-info">
              <span className="tool-label-text">
                {tools[currentToolKey].label}
              </span>{" "}
              (
              {toolDurabilities[currentToolKey] === Infinity
                ? "∞"
                : toolDurabilities[currentToolKey]}
              )
            </span>
            <span className="dropdown-arrow">&#9662;</span>
          </button>
          {isOpen && (
            <div
              className="tool-selector-menu"
              role="listbox"
              ref={dropdownMenuRef}
              onKeyDown={(e) => {
                // Optional: Implement keyboard navigation here
              }}
            >
              {renderToolOptions()}
            </div>
          )}
        </div>
      ) : (
        // Show a non-interactive label if no tools are available
        <div className="current-tool-display">
          <span className="tool-info">
            <span className="tool-label-text">No Tool Available</span>
          </span>
        </div>
      )}
    </div>
  );
};

// PropTypes for better type checking and documentation
ToolSelector.propTypes = {
  selectedTool: PropTypes.string.isRequired,
  onSelectTool: PropTypes.func.isRequired,
  tools: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  toolDurabilities: PropTypes.objectOf(PropTypes.number).isRequired,
};

export default ToolSelector;
