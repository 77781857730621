import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Post from "../components/Post";
import ClosableOverlay from "../components/ClosableOverlay";
import ReactionsFeed from "../components/ReactionsFeed";
import { useDoodleContext } from "../components/DoodleContext";
import Loader from "../components/Loader";
import { useUserContext } from "../components/UserContext";
import { AxiosContext } from "../contexts/AxiosContext";

const SinglePost = ({
  handleOpenDoodleEditor,
  onFollowChange,
  isViewingOwnWall,
}) => {
  const { pathUsername, post_id } = useParams();
  const { isReactionsOpen, closeReactionsModal } = useDoodleContext();
  const { userId, isAuthenticated } = useUserContext();

  const [doodle, setDoodle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const axiosInstance = React.useContext(AxiosContext);

  useEffect(() => {
    const fetchDoodle = async () => {
      try {
        const response = await axiosInstance.get(`/single-post/${pathUsername}/${post_id}/`);
        setDoodle(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching doodle:", err);

        // Logging the error response structure to ensure we understand its format
        console.error("Error response structure:", err.response);

        // Extract the error message with a more robust approach
        const errorMessage =
          err.response?.data?.detail || // Check if there's a `detail` field
          (err.response?.status === 404
            ? "There is no such post. It may have been deleted or does not exist."
            : err.response?.data?.message) || // Sometimes, the error message might be under `message`
          "Error loading the post. Please try again later.";

        setError(errorMessage);
        setLoading(false);
      }
    };

    fetchDoodle();
  }, [post_id, pathUsername, axiosInstance]);

  if (loading) {
    return (
      <main className="main-content">
        <Loader />
      </main>
    );
  }

  if (error) {
    return (
      <main className="main-content">
        <p>{error}</p>
      </main>
    );
  }

  return (
    <main className="main-content">
      {isReactionsOpen && (
        <ClosableOverlay onClose={closeReactionsModal}>
          <ReactionsFeed
            userId={userId}
            handleOpenDoodleEditor={handleOpenDoodleEditor}
            isViewingOwnWall={isViewingOwnWall}
          />
        </ClosableOverlay>
      )}
      <div className="feed">
        <div className="single-post">
          <Post
            key={doodle.id}
            doodle={doodle}
            typeOfDoodle={"doodles"}
            pathUsername={pathUsername}
            handleOpenDoodleEditor={handleOpenDoodleEditor}
            onFollowChange={onFollowChange}
            isViewingOwnWall={isViewingOwnWall}
          />
        </div>
      </div>
    </main>
  );
};

export default SinglePost;