import React, { useContext, useState, useEffect } from "react";
import { AxiosContext } from "../contexts/AxiosContext";
import { useUserContext } from "../components/UserContext";
import Loader from "./Loader";
import { Link } from "react-router-dom";

const Follow = ({
  followed: initialFollowed,
  userId,
  onFollowChange,
  isInList,
  className,
  customElement,
  handleProtectedButtonClick,
}) => {
  const axiosInstance = useContext(AxiosContext);
  const { isAuthenticated, ShouldShowSignUpPrompt, setIsAuthenticated } =
    useUserContext();
  const [followed, setFollowed] = useState(initialFollowed);
  const [loading, setLoading] = useState(initialFollowed === null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (initialFollowed === null && isAuthenticated) {
      fetchFollowStatus();
    }
  }, [initialFollowed, isAuthenticated]);

  const fetchFollowStatus = async () => {
    try {
      const response = await axiosInstance.get(
        `/check-if-user-is-followed/${userId}`
      );
      setFollowed(response.data.followed);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setIsAuthenticated(false);
      } else {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFollow = async () => {
    if (!isAuthenticated && ShouldShowSignUpPrompt()) {
      handleProtectedButtonClick();
      return;
    }

    try {
      const action = followed ? "unfollow" : "follow";
      await axiosInstance.post(`/follow/`, { userId, action });
      setFollowed(!followed);
      onFollowChange(!followed, userId);
    } catch (error) {
      console.error("Error following:", error);
      if (error.response && error.response.status === 401) {
        setIsAuthenticated(false);
      }
    }
  };

  if (!isAuthenticated) {
    return (
      <Link to="/signin">
        <div className={`${className}`}>Login to follow </div>
      </Link>
    );
  }

  return (
    <>
      {!error ? (
        <div
          className={`${className} ${followed ? "active" : ""}`}
          onClick={handleFollow}
          disabled={loading}
        >
          {loading ? (
            <Loader />
          ) : customElement ? (
            customElement
          ) : followed ? (
            "Unfollow"
          ) : (
            "Follow"
          )}
        </div>
      ) : (
        <div>Error, try again later</div>
      )}
    </>
  );
};

export default Follow;
