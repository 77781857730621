import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Follow from "../components/Follow";
import Loader from "../components/Loader";
import UserAvatar from "./UserAvatar";
import usePagination from "../hooks/usePagination";
import { useInView } from "react-intersection-observer";

const FollowsList = ({
  listType,
  onClose,
  username,
  loggedInUserId,
  onFollowChange,
  setUsersList,
  usersList,
}) => {
  const initialUrl = `/user-relationships/${username}/${listType}`;
  const { items, setItems, total, loading, error, loadMore, hasMore, setUrl } =
    usePagination(initialUrl, 10);

  const [isDebouncing, setIsDebouncing] = useState(false);

  const debouncedLoadMore = useCallback(() => {
    if (!isDebouncing && hasMore && !loading) {
      loadMore();
      setIsDebouncing(true);
      setTimeout(() => {
        setIsDebouncing(false);
      }, 100);
    }
  }, [isDebouncing, hasMore, loading, loadMore]);

  useEffect(() => {
    // Update URL when listType or username changes
    setUrl(`/user-relationships/${username}/${listType}`);
    setItems([]);
  }, [listType, username, setUrl, setItems]);

  // Intersection observer to trigger loading more items
  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "200px",
  });

  useEffect(() => {
    if (inView && hasMore && !loading) {
      debouncedLoadMore();
    }
  }, [inView, hasMore, loading, debouncedLoadMore]);

  useEffect(() => {
    setUsersList(items);
  }, [items, setUsersList]);

  return (
    <div>
      <h2>{listType === "follows" ? "Follows" : "Followers"}</h2>
      {loading && usersList.length === 0 ? (
        <Loader />
      ) : (
        <div className="user-list">
          {usersList.map((user) => (
            <div key={user.id} className="user-item">
              <UserAvatar
                avatarUrl={user.avatar}
                username={user.username}
                userLevel={user.user_level}
              />
              <div className="user-info">
                <div className="user-name">
                  <Link to={`/${user.username}`} onClick={onClose}>{user.username}</Link>
                </div>
                <div className="user-stats">
                  <div className="stat-item">
                    <span className="stat-number">{user.follows_count}</span>{" "}
                    Follows
                  </div>
                  <div className="stat-item">
                    <span className="stat-number">{user.followers_count}</span>{" "}
                    Followers
                  </div>
                </div>
              </div>
              {user.id !== loggedInUserId && (
                <Follow
                  followed={user.is_followed}
                  userId={user.id}
                  isInList={true}
                  onFollowChange={onFollowChange}
                  className={"user-action-button"}
                />
              )}
            </div>
          ))}
          {loading && <Loader />}
          {!loading && !hasMore && usersList.length === 0 && (
            <p>Nothing here yet.</p>
          )}
          {error && <p>{error}</p>}
          <div ref={ref}></div>
        </div>
      )}
    </div>
  );
};

export default FollowsList;
