import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithLoader from "../components/ImageWithLoader";

const ReactionsPreview = ({ doodle, openReactionsModal }) => {
  
  return (
    <div
      className="reaction-previews"
      onClick={() => openReactionsModal(doodle)}
    >
      {doodle.reactions && doodle.reactions.length > 0 ? (
        doodle.reactions.map((reaction, index) => (
          <div key={index} className="entry">
            <Link
              className="username"
              to={`/${reaction.belongs_to_user.username}`}
            >
              {reaction.belongs_to_user.username}
            </Link>
            <ImageWithLoader
              src={reaction.thumbnail}
              alt={`Reaction ${index}`}
              containerClassName="thumbnail-container"
              imageClassName="thumbnail"
            />
          </div>
        ))
      ) : (
        <p>No reactions yet.</p>
      )}
    </div>
  );
};

export default ReactionsPreview;
