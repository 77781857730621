// src/components/LowerNav.js

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faMessage,
  faBell,
  faBurst,
  faRightToBracket,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNotifications } from "../contexts/NotificationsContext";
import PropTypes from "prop-types";
import { useUserContext } from "../components/UserContext";

const LowerNav = () => {
  const location = useLocation();
  const { notifications } = useNotifications();
  const { isAuthenticated } = useUserContext();

  // Define paths where the LowerNav should be hidden
  const hideNavPaths = ["/signin", "/signup"];

  // Check if the current path starts with any of the hideNavPaths
  const shouldHideNav = hideNavPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  // Hide the component if on a hidden path or if isAuthenticated is null
  if (shouldHideNav || isAuthenticated === null) {
    return null; // Do not render LowerNav
  }

  return (
    <nav className="lower-nav">
      {isAuthenticated ? (
        // Authenticated User's LowerNav
        <>
          <Link to="/" aria-label="Feed" className="nav-button">
            <div className="icon-container">
              <FontAwesomeIcon icon={faHouse} className="icon" />
            </div>
          </Link>
          <Link
            to="/notifications"
            aria-label="Notifications"
            className="nav-button"
          >
            <div className="icon-container">
              <FontAwesomeIcon icon={faBell} className="icon" />
              {notifications.unreadNotifications > 0 && (
                <div className="notifications-counter">
                  {notifications.unreadNotifications}
                </div>
              )}
            </div>
          </Link>
          <Link to="/challenges" aria-label="Challenges" className="nav-button">
            <div className="icon-container">
              <FontAwesomeIcon icon={faBurst} className="icon" />
              {notifications.openChallenges > 0 && (
                <div className="notifications-counter">
                  {notifications.openChallenges}
                </div>
              )}
            </div>
          </Link>
          <Link to="/chats" aria-label="Chats" className="nav-button">
            <div className="icon-container">
              <FontAwesomeIcon icon={faMessage} className="icon" />
              {notifications.unreadMessages > 0 && (
                <div className="notifications-counter">
                  {notifications.unreadMessages}
                </div>
              )}
            </div>
          </Link>
        </>
      ) : (
        // Unauthenticated User's LowerNav
        <>
          <Link to="/signin" aria-label="Sign In" className="nav-button">
            <div className="icon-container">
              <FontAwesomeIcon icon={faRightToBracket} className="icon" />
              <span>Sign In</span>
            </div>
          </Link>
          <Link to="/signup" aria-label="Sign Up" className="nav-button">
            <div className="icon-container">
              <FontAwesomeIcon icon={faUserPlus} className="icon" />
              <span>Sign Up</span>
            </div>
          </Link>
        </>
      )}
    </nav>
  );
};

export default LowerNav;
