import React, { createContext, useContext, useState, useEffect } from "react";
import emitter from "../eventEmitter";

const UserContext = createContext();

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  // Set authenticated status to null to indicate that the authentication status hasn't been checked yet
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  // State for authenticated user id
  const [userId, setUserId] = useState(null);

  // State for authenticated user name
  const [userName, setUserName] = useState(null);

  // State for authenticated user avatar
  const [userAvatar, setUserAvatar] = useState(null);

  // State for authenticated user avatar
  const [userFollows, setUserFollows] = useState(null);
  const [userFollowers, setUserFollowers] = useState(null);

  // States for progression counters
  const [userScore, setUserScore] = useState(0);
  const [userLevel, setUserLevel] = useState(0);
  const [levelCompletionPercent, setLevelCompletionPercent] = useState(0);

  // Sign up prompt states and logic
  const [isSignUpPromptOpen, setIsSignUpPromptOpen] = useState(false);

  const handleCloseSignUpPrompt = () => {
    setIsSignUpPromptOpen(false);
  };

  const ShouldShowSignUpPrompt = () => {
    if (!isAuthenticated) {
      setIsSignUpPromptOpen(true);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // Listen for the "unauthenticated" event to set authentication state to false
    const handleUnauthenticated = () => {
      setIsAuthenticated(false);
    };

    emitter.on("unauthenticated", handleUnauthenticated);

    // Cleanup listener on unmount
    return () => {
      emitter.off("unauthenticated", handleUnauthenticated);
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        userId,
        userName,
        userAvatar,
        userFollows,
        userFollowers,
        userScore,
        userLevel,
        levelCompletionPercent,
        isSignUpPromptOpen,
        setIsAuthenticated,
        setUserId,
        setUserName,
        setUserAvatar,
        setUserFollows,
        setUserFollowers,
        setUserScore,
        setUserLevel,
        setLevelCompletionPercent,
        setIsSignUpPromptOpen,
        handleCloseSignUpPrompt,
        ShouldShowSignUpPrompt,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
