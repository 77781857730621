import { sha256 } from 'js-sha256';

export const hashImage = (imageBlob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      // Use the correct method to read the image as an ArrayBuffer
      reader.onloadend = () => {
        const binaryData = reader.result;  // This will be an ArrayBuffer
        if (binaryData) {
          const imageHash = sha256(binaryData);  // Hash the binary data
          resolve(imageHash);
        } else {
          reject(new Error("Error reading image data"));
        }
      };
  
      reader.onerror = (error) => reject(new Error("Error reading blob as array buffer: " + error));
      
      // Read the blob as ArrayBuffer (which is suitable for hashing)
      reader.readAsArrayBuffer(imageBlob);
    });
  };

export const generateMetaHash = async (imageHash, colors, tools, intention, context) => {
  try {
    // Convert the colors and tools to strings
    const colorsString = JSON.stringify(colors);
    const toolsString = JSON.stringify(tools);
    const contextString = JSON.stringify(context);
    
    // Combine all the components into a single string
    const combinedString = colorsString + contextString + toolsString + intention + imageHash;
    
    // Hash the combined string
    const combinedStringHash = sha256(combinedString);
    
    return combinedStringHash;  // Return the final combined hash
  } catch (error) {
    console.error('Error generating meta hash:', error);
    throw error;
  }
};
