// ImageWithLoader.js
import React, { useState } from 'react';

const ImageWithLoader = ({ src, alt, containerClassName, imageClassName = '', ...props }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <div className={`${containerClassName} ${isImageLoaded ? '' : 'loading'}`}>
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        className={`${imageClassName} ${isImageLoaded ? 'loaded' : ''}`}
        {...props}
      />
    </div>
  );
};

export default ImageWithLoader;