// src/contexts/NotificationsContext.js

import React, { createContext, useState, useEffect, useContext } from "react";
import { useUpdates } from "./UpdatesContext"; // Import updates for any additional data needs

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children, isAuthenticated }) => {
  const [notifications, setNotifications] = useState({
    unreadMessages: null,
    unreadNotifications: null,
    openChallenges: null,
  });

  const { updates } = useUpdates(); // Use updates context if additional fields are needed

  useEffect(() => {
    if (updates.unreadMessages !== undefined && updates.unreadNotifications !== undefined) {
      setNotifications({
        unreadMessages: updates.unreadMessages,
        unreadNotifications: updates.unreadNotifications,
        openChallenges: updates.openChallenges,
      });
    }
  }, [updates]);

  // Restore incrementUnreadCounter using setNotifications directly
  const incrementUnreadCounter = (amount, counterType) => {
    setNotifications((prevNotifications) => ({
      ...prevNotifications,
      [counterType]: (prevNotifications[counterType] || 0) + amount,
    }));
  };

  return (
    <NotificationsContext.Provider
      value={{ notifications, setNotifications, incrementUnreadCounter }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  return useContext(NotificationsContext);
};