// ColorPicker.js
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const ColorPicker = ({
  selectedColor,
  selectedColorLabel,
  onSelectColor,
  colors,
  hasPalette,
  colorQuantities,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  // Determine the current color key based on the selected color
  const currentColorKey =
    Object.keys(colors).find((key) => colors[key].color === selectedColor) ||
    Object.keys(colors)[0] ||
    "";

  // Close the dropdown when clicking or tapping outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("touchstart", handleClickOutside);
    } else {
      window.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("touchstart", handleClickOutside);
    }

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("touchstart", handleClickOutside);
    };
  }, [isOpen]);

  // Render color options as swatches
  const renderColorOptions = () => (
    <div className="color-options">
      {Object.keys(colors).map((key) => (
        <button
          key={key}
          className={`color-option-button ${
            colors[key].color === selectedColor ? "selected" : ""
          }`}
          onClick={() => {
            onSelectColor(colors[key].color, colors[key].label);
            setIsOpen(false);
          }}
          disabled={colorQuantities[key] === 0}
          title={`${colors[key].label} ${
            colorQuantities[key] === 0 ? "(Out of stock)" : ""
          }`}
          tabIndex="0" // Make focusable
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSelectColor(colors[key].color, colors[key].label);
              setIsOpen(false);
            }
          }}
        >
          <span
            className="color-swatch"
            style={{
              backgroundColor: colors[key].color,
              border:
                colors[key].color === selectedColor
                  ? "2px solid #000"
                  : "1px solid #ccc",
            }}
          />
          <span className="color-label">
            {colors[key].label} (
            {colorQuantities[key] === Infinity
              ? "∞"
              : colorQuantities[key]}
            )
          </span>
        </button>
      ))}
    </div>
  );

  // Determine if the toggle button should be disabled
  const isToggleDisabled = !hasPalette;

  // Prevent opening the menu if disabled
  const handleToggleClick = () => {
    if (!isToggleDisabled) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div className={`color-picker ${isOpen ? "open" : ""}`} ref={dropdownRef}>
      <div className="color-picker-dropdown">
        <button
          id="color-picker-toggle"
          className={`current-color-button ${
            isToggleDisabled ? "disabled" : ""
          }`}
          onClick={handleToggleClick}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          ref={toggleButtonRef}
          disabled={isToggleDisabled}
        >
          <span
            className="color-swatch"
            style={{
              backgroundColor: selectedColor,
            }}
          />
          <span className="color-info">
            <span className="color-label-text">{selectedColorLabel}</span>{" "}
            (
            {colorQuantities[currentColorKey] === Infinity
              ? "∞"
              : colorQuantities[currentColorKey]}
            )
          </span>
          <span className="dropdown-arrow">&#9662;</span>
        </button>
        {isOpen && hasPalette && (
          <div
            className="color-picker-menu"
            role="listbox"
            ref={dropdownMenuRef}
            onKeyDown={(e) => {
              // Optional: Implement keyboard navigation here
            }}
          >
            {renderColorOptions()}
          </div>
        )}
      </div>
    </div>
  );
};

// PropTypes for better type checking and documentation
ColorPicker.propTypes = {
  selectedColor: PropTypes.string.isRequired,
  selectedColorLabel: PropTypes.string.isRequired,
  onSelectColor: PropTypes.func.isRequired,
  colors: PropTypes.objectOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  hasPalette: PropTypes.bool.isRequired,
  colorQuantities: PropTypes.objectOf(PropTypes.number).isRequired,
};

export default ColorPicker;