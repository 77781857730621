// src/contexts/UpdatesContext.js

import React, { createContext, useState, useContext, useEffect } from "react";
import emitter from '../eventEmitter';

const UpdatesContext = createContext();

export const UpdatesProvider = ({ children }) => {
  const [updates, setUpdates] = useState({
    unreadMessages: null,
    unreadNotifications: null,
    openChallenges: null,
    newScore: null,
    newLevel: null,
    newLevelCompletionPercent: null,
    instantMessage: null,
    showMoodPrompt: null,
  });

  useEffect(() => {
    // Event handlers
    const handleNewScore = (newScore) => {
      setUpdates((prevUpdates) => ({ ...prevUpdates, newScore }));
    };

    const handleNewLevel = (newLevel) => {
      setUpdates((prevUpdates) => ({ ...prevUpdates, newLevel }));
    };

    const handleLevelCompletionPercent = (percent) => {
      setUpdates((prevUpdates) => ({
        ...prevUpdates,
        newLevelCompletionPercent: percent,
      }));
    };

    // Subscribe to events
    emitter.on("updateNewScore", handleNewScore);
    emitter.on("updateNewLevel", handleNewLevel);
    emitter.on("updateLevelCompletionPercent", handleLevelCompletionPercent);

    // Clean up on unmount
    return () => {
      emitter.off("updateNewScore", handleNewScore);
      emitter.off("updateNewLevel", handleNewLevel);
      emitter.off("updateLevelCompletionPercent", handleLevelCompletionPercent);
    };
  }, []);

  return (
    <UpdatesContext.Provider value={{ updates, setUpdates }}>
      {children}
    </UpdatesContext.Provider>
  );
};

export const useUpdates = () => {
  return useContext(UpdatesContext);
};
