import React, { useEffect, useState, useContext } from "react";
import { AxiosContext } from "../../contexts/AxiosContext";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import { formatDateTime } from "../../utils/dateUtils";

const RecentScoreIncrements = ({ userName, onClose }) => {
  const axiosInstance = useContext(AxiosContext);
  const [scoreIncrements, setScoreIncrements] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchScoreIncrements = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axiosInstance.get(`/user/score-increments/`);
        setScoreIncrements(response.data);
      } catch (err) {
        setError("Failed to fetch score increments.");
      } finally {
        setLoading(false);
      }
    };

    fetchScoreIncrements();
  }, [userName]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="recent-score-increments">
      <div className="score-increments-list">
        {scoreIncrements.map((increment) => (
          <div key={increment.id} className="score-increment-item">
            <span className="timestamp">
              {formatDateTime(increment.timestamp)}
            </span>{" "}
            <span className="increment-value">
              {increment.increment_value > 0
                ? `+${increment.increment_value}`
                : increment.increment_value}{" "}
              {increment.event_type === "score" && "score"}
            </span>{" "}
            <span className="description">{increment.event_description}</span>{" "}
            {increment.related_user && (
              <span className="related-user-wrapper">
                {"("}
                <Link
                  to={`/${increment.related_user.username}`}
                  className="related-user"
                  onClick={onClose}
                >
                  {increment.related_user.username}
                </Link>
                {")"}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentScoreIncrements;
