import React, { useState } from "react";
import { Link } from "react-router-dom";
import UserAvatar from "../UserAvatar";
import Follow from "../Follow";

const UserSuggestionItem = ({ user, onFollowChange }) => {
  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleFollow = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      onFollowChange(); // Trigger the callback to remove the user from the list
    }, 300); // Match this duration with the CSS transition time
  };

  return (
    <div className={`user-item-container ${isFadingOut ? "fade-out" : ""}`}>
      <UserAvatar
        avatarUrl={user.avatar}
        username={user.username}
        userLevel={user.user_level}
      />
      <div className="user-info">
        <div className="user-name">
          <Link to={`/${user.username}`}>{user.username}</Link>
        </div>
        <div className="user-stats">
          <div className="stat-item">
            <span className="stat-number">{user.followers_count}</span> Followers
          </div>
        </div>
      </div>
      <Follow
        followed={user.is_followed}
        userId={user.id}
        className="user-action-button"
        onFollowChange={handleFollow}
      />
    </div>
  );
};

export default UserSuggestionItem;
