import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ClosableOverlay = ({ onClose, children }) => (
  <div className="closable-overlay show-overlay">
    <div className="close-button" onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} />
    </div>
    <div className="overlay-content">
      {children}
    </div>
  </div>
);

ClosableOverlay.propTypes = {
  onClose: PropTypes.func.isRequired, // Callback function for closing the overlay
  children: PropTypes.node.isRequired, // Content to render inside the overlay
};

export default ClosableOverlay;