// usePagination.js

import { useState, useEffect, useCallback } from "react";
import createAxiosInstance from "../axiosInstance";

/**
 * Custom hook for handling pagination.
 * @param {string} initialUrl - The initial URL for data fetching.
 * @param {number} initialPageSize - Number of items per page.
 * @param {boolean} reverseOrder - If true, reverses the order of items within each page.
 * @returns {object} - Returns pagination state and functions.
 */
const usePagination = (initialUrl, initialPageSize, reverseOrder = false) => {
  const [url, setUrl] = useState(initialUrl);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const axiosInstance = createAxiosInstance();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [stopLoading, setStopLoading] = useState(false);

  /**
   * Function to load more data.
   * Manages pagination state and handles errors.
   */
  const loadMore = useCallback(async () => {
    if (loading || stopLoading) return;
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get(url, {
        params: {
          page,
          pageSize,
        },
      });
      let newItems = response.data.results;
      if (reverseOrder) {
        // Reverse the order of messages within each page
        newItems = newItems.reverse();
      }
      setItems((prevItems) => {
        return reverseOrder
          ? [...newItems, ...prevItems]
          : [...prevItems, ...newItems]; // Append new items based on reverse order
      });
      setTotal(response.data.total);
      setPage((prevPage) => prevPage + 1);
      setHasMore(response.data.next != null);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
      setStopLoading(true);
    } finally {
      setLoading(false);
    }
  }, [url, page, pageSize, loading, stopLoading, reverseOrder]);

  /**
   * Reset pagination when URL changes.
   * This ensures that when the sort order or other parameters change,
   * the pagination state resets correctly.
   */
  useEffect(() => {
    // Reset pagination state
    setItems([]);
    setPage(1);
    setStopLoading(false);
    setHasMore(true);
    setError(null); // Reset error when URL changes
  }, [url]);

  return {
    items,
    setItems,
    total,
    loading,
    error,
    loadMore,
    hasMore,
    url,
    setUrl,
    page,
    setPage,
  };
};

export default usePagination;
