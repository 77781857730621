import React, { createContext, useState } from "react";
import ReactDOM from "react-dom";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);

  const showModal = (content) => setModalContent(content);
  const hideModal = () => setModalContent(null);

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {modalContent &&
        ReactDOM.createPortal(modalContent, document.getElementById("modal-root"))}
    </ModalContext.Provider>
  );
};